/** @jsx jsx */
import PropTypes from "prop-types";

import { Alert, Text, jsx } from "theme-ui";
import {
  ActionButton,
  DefaultButton,
  Panel,
  Stack,
  StackItem,
} from "office-ui-fabric-react";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import React, { useCallback, useEffect, useState } from "react";
import { get } from "sites-common/utils/lodash";
import AbhyasiEMISearchBox from "../AbhyasiEMISearchBox";
import useAuth from "../../auth/useAuth";

const PeopleSelector = ({
  btnProps,
  btnType,
  btnDisabled,
  panelHeader,
  onSelectionDone,
  showMyself,
  selectCount,
  restrictions,
  searchLabel,
  searchModes,
}) => {
  const { srcmProfile } = useAuth();
  const [show, { toggle }] = useMyBoolean(false);

  const [selectedList, setSelectedList] = useState({});

  const countSelected = Object.values(selectedList).filter(
    (x) => x && x.id
  ).length;

  const renderSelectBtn = useCallback(
    (item) => {
      const selected = get(selectedList, item.id, false);

      return (
        <DefaultButton
          toggle
          text={selected ? "Selected" : "Select"}
          iconProps={{ iconName: selected ? "CheckBoxComposite" : "CheckBox" }}
          checked={selected}
          onClick={() =>
            setSelectedList((p) => ({
              ...p,
              [item.id]: selected ? false : item,
            }))
          }
        />
      );
    },
    [selectedList, setSelectedList]
  );

  const onDone = useCallback(() => {
    onSelectionDone(selectedList);
    toggle();
  }, [toggle, selectedList, onSelectionDone]);

  const onRenderFooterContent = React.useCallback(
    () => (
      <div>
        <div style={{ margin: 8 }}>
          {countSelected ? (
            <Text>
              Selected{" "}
              <b>
                {countSelected} person{countSelected > 1 && "s"}
              </b>
              .{selectCount === 0 && "You can search and select more! "}
              {selectCount > 0 &&
                selectCount < countSelected &&
                `Search and select ${
                  countSelected - selectCount
                } more to continue!`}
              {selectCount > 0 &&
                selectCount > countSelected &&
                `Only ${selectCount} persons can be selected`}
            </Text>
          ) : (
            <Text>Search and select a person to continue!</Text>
          )}
        </div>
        <Stack horizontal horizontalAlign="space-between">
          <DefaultButton
            onClick={toggle}
            text="Cancel"
            iconProps={{ iconName: "Cancel" }}
          />

          <DefaultButton
            onClick={onDone}
            disabled={
              !countSelected ||
              (selectCount > 0 && selectCount !== countSelected)
            }
            iconProps={{ iconName: "Forward" }}
            primary
            text="Continue"
          />
        </Stack>
      </div>
    ),
    [onDone, countSelected, toggle, selectCount]
  );

  useEffect(() => {
    if (!show) {
      setSelectedList({});
    }
  }, [show, setSelectedList]);

  const selectMyself = useCallback(() => {
    onSelectionDone({ [srcmProfile?.rec?.id]: srcmProfile?.rec });
    toggle();
  }, [onSelectionDone, toggle, srcmProfile]);

  if (srcmProfile && !("rec" in srcmProfile)) {
    return <Alert>Session Error! Please Sign out and Sign in again!</Alert>;
  }
  return (
    <React.Fragment>
      <Stack tokens={{ childrenGap: 4 }}>
        {showMyself && (
          <StackItem horizontal>
            {btnType === "default" ? (
              <DefaultButton
                disabled={show || btnDisabled}
                {...btnProps}
                onClick={selectMyself}
                iconProps={{ iconName: "Contact" }}
                text="Select Myself"
              />
            ) : (
              <ActionButton
                disabled={show || btnDisabled}
                {...btnProps}
                onClick={selectMyself}
                iconProps={{ iconName: "Contact" }}
                text="Select Myself"
              />
            )}
          </StackItem>
        )}
        {srcmProfile?.isAtleastPractitioner && (
          <StackItem horizontal>
            {btnType === "default" ? (
              <DefaultButton
                disabled={show || btnDisabled}
                {...btnProps}
                onClick={toggle}
              />
            ) : (
              <ActionButton
                disabled={show || btnDisabled}
                {...btnProps}
                onClick={toggle}
              />
            )}
          </StackItem>
        )}
      </Stack>

      <Panel
        hasCloseButton={false}
        isOpen={show}
        onDismiss={toggle}
        headerText={panelHeader}
        isFooterAtBottom
        onRenderFooterContent={onRenderFooterContent}
        styles={{
          main: { backgroundColor: "#dadada" },
          overlay: { backgroundColor: "rgba(0,0,0, 0.7)" },
        }}
      >
        <Stack>
          <AbhyasiEMISearchBox
            searchModes={searchModes}
            showClearBtn={false}
            renderSelectBtn={renderSelectBtn}
            restrictions={restrictions}
            searchLabel={searchLabel}
          />
          <Stack horizontal />
        </Stack>
      </Panel>
    </React.Fragment>
  );
};
PeopleSelector.propTypes = {
  btnProps: PropTypes.shape({}),
  btnType: PropTypes.string,
  btnDisabled: PropTypes.bool,
  onSelectionDone: PropTypes.func.isRequired,
  panelHeader: PropTypes.string,
  showMyself: PropTypes.bool,
  selectCount: PropTypes.number,
  searchLabel: PropTypes.string,
  searchModes: PropTypes.arrayOf(PropTypes.oneOf("basic", "bulk", "advanced")),
  restrictions: PropTypes.arrayOf(PropTypes.string),
};
PeopleSelector.defaultProps = {
  btnType: "default",
  btnProps: { text: "Select People", iconProps: { iconName: "Family" } },
  btnDisabled: false,
  panelHeader: "Search & Select",
  showMyself: true,
  selectCount: 0,
  searchLabel: null,
  searchModes: ["bulk", "basic"],
  restrictions: null,
};
export default PeopleSelector;
