/** @jsx jsx */
import { Text, jsx, Badge } from "theme-ui";
import React, { useCallback, useState } from "react";
import SrcmSearchPage from "gatsby-plugin-hfn-profile/components/SrcmSearchPage";
import SearchId from "gatsby-plugin-hfn-profile/components/SrcmModels/SearchId";
import { doFormatShortDate } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import PropTypes from "prop-types";
import { DynamicField } from "sites-common/components/DynamicField";
import { useQueryParam, StringParam } from "use-query-params";

import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import AbhyasiEntity from "gatsby-plugin-hfn-profile/components/SrcmEntities/AbhyasiEntity";
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  Spinner,
} from "office-ui-fabric-react";
import { buttonStyles } from "sites-common/utils/fabricStyles";

import PeopleSelector from "gatsby-plugin-hfn-profile/components/PeopleSelector";
import DefaultLayout from "../../layouts/default";

const ApproveRejectBtn = ({ id, action, onAction }) => {
  const { fetchSrcmApi } = useFetchSrcmApi();
  const [notes, setNotes] = useState("");
  const { srcmProfile } = useAuth();
  const approved_by = srcmProfile?.id;
  const [f, setF] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(() => {
    const methodParams = {
      notes,
      approval_type: "seeker_to_practioner",
      approved_by,
      first_sitting_by: f?.id,
    };
    if (action === "reject") {
      if (!notes) {
        setError("Missing Rejection Notes");
        return;
      }
      if (notes.length < 50) {
        setError(
          "Rejection Note is too short.  Please write atleast one or two sentences on why the candidate is being rejected"
        );
        return;
      }
    }
    if (action === "approve" && !f?.id) {
      setError("Missing Preceptor Name for 'First Sitting By'");
      return;
    }
    setLoading(true);
    setError(null);
    fetchSrcmApi({
      api: `/api/v2/me/approvals/${id}/${action}/`,
      client: "preceptorCandidateAppClient",
      method: "POST",
      methodParams,
    })
      .then(() => {
        setLoading(false);
        onAction(action);
      })
      .catch((e) => {
        setError(e.toString());
        setLoading(false);
        // onAction(action);
      });
  }, [
    id,
    action,
    fetchSrcmApi,
    onAction,
    f,
    notes,
    approved_by,
    setError,
    setLoading,
  ]);

  return (
    <div>
      {action === "approve" && (
        <React.Fragment>
          <Text variant="description">First Sitting By (Preceptor) *</Text>
          <div>
            <b>{f?.name}</b>
          </div>
          <PeopleSelector
            searchLabel="Preceptor's Name or ID or Email or Mobile"
            showMyself={false}
            selectCount={1}
            restrictions={[":record_type", "==", "t"]}
            btnProps={{
              styles: buttonStyles.blueLight,
              text: f.id ? "Change Preceptor" : "Select Preceptor",
              iconProps: { iconName: f.id ? "Edit" : "Contact" },
            }}
            onSelectionDone={(people) => Object.values(people).forEach(setF)}
            searchModes={["advanced"]}
          />
        </React.Fragment>
      )}
      <Text variant="description">
        {action === "approve" ? "Approval" : "Rejection"} Notes{" "}
        {action === "reject" && "*"}
      </Text>
      <DynamicField
        type="text"
        variant="border-bottom"
        required={false}
        multiline={action === "reject"}
        name="notes"
        onChange={setNotes}
        value={notes}
      />
      {error && (
        <MessageBar messageBarType={MessageBarType.error}>{error} </MessageBar>
      )}
      {loading && <Spinner />}

      {action === "reject" ? (
        <DefaultButton
          styles={buttonStyles.danger}
          iconProps={{ iconName: "Cancel" }}
          sx={{ m: 2 }}
          onClick={onClick}
          text="Reject"
        />
      ) : (
        <DefaultButton
          styles={buttonStyles.warning}
          iconProps={{ iconName: "Accept" }}
          sx={{ m: 2 }}
          onClick={onClick}
          text="Approve"
        />
      )}
    </div>
  );
};

ApproveRejectBtn.propTypes = {
  id: PropTypes.number.isRequired,
  action: PropTypes.oneOf(["approve", "reject"]).isRequired,
  onAction: PropTypes.func.isRequired,
};

const matchEmail = (t) =>
  t.match(/[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9]+/g) || [];
const matchPhone = (t) =>
  (t.match(/[+][1-9]{1}[0-9 ]+|[0-9]{10}/g) || []).map((x) =>
    /^\d{10}/.test(x) ? `+91${x}` : x
  );

const SearchButton = ({ skey, value, ...props }) => (
  <DefaultButton
    sx={{ mr: 1, mb: 1, borderRadius: "20px", backgroundColor: "#E8E8F0" }}
    href={`search-practitioners/?k=${skey}&q=${encodeURIComponent(value)}`}
    text={`🔍 ${value}`}
    target="_blank"
    {...props}
  />
);

SearchButton.propTypes = {
  skey: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
const colDefns = [
  [
    "Pending Requests",
    (r) => {
      const [, model, id] = r.search_url ? r.search_url.split("/") : [];

      let x = {};
      try {
        const y = JSON.parse(r.notes);

        if (!!y && typeof y === "object") {
          x = y;
        }
      } catch (e) {
        //
      }
      const { doj, city, detail } = x;
      const [done, setDone] = useState(false);

      if (!model || !id) {
        return "Invalid Entry";
      }

      return (
        <div sx={{ my: 2, p: 2, bg: "white", width: "100%" }}>
          <div>
            <Badge variant="info">New Practitioner</Badge>
            <div style={{ float: "right" }}>
              <Text variant="description">{r.id}</Text>
            </div>
          </div>
          <SearchId
            model={model}
            id={id}
            client="preceptorCandidateAppClient"
            render={(r1) => {
              return (
                <div>
                  <Text sx={{ mr: 3 }} variant="title">
                    {r1.name}
                  </Text>
                  <SearchButton skey="name" value={r1.name} />
                  <br />
                  <Text sx={{ mr: 3 }} variant="subtitle">
                    {r1.email}
                  </Text>
                  <SearchButton skey="email" value={r1.email} />

                  <br />
                </div>
              );
            }}
          />
          <div sx={{ mb: 2 }}>
            <table>
              <tbody>
                {!doj && (
                  <tr>
                    <td sx={{ border: "1px solid grey" }}>
                      <small>Notes: </small>
                    </td>
                    <td sx={{ border: "1px solid grey" }}>
                      {r.notes
                        ? r.notes.split(",").map((a) => <div>{a}</div>)
                        : "-NA-"}
                      <br />
                      <Text variant="description">
                        Detected Email/Mobile in Notes
                      </Text>
                      <br />
                      {matchPhone(r.notes).map((r1) => (
                        <SearchButton skey="mobile" value={r1} />
                      ))}
                      {matchEmail(r.notes).map((r1) => (
                        <SearchButton skey="email" value={r1} />
                      ))}
                    </td>
                  </tr>
                )}
                {doj && (
                  <React.Fragment>
                    <tr>
                      <td sx={{ border: "1px solid grey" }}>
                        <small>First Sitting: </small>
                      </td>
                      <td sx={{ border: "1px solid grey" }}>
                        {doFormatShortDate(doj)}
                      </td>
                    </tr>
                    <tr>
                      <td sx={{ border: "1px solid grey" }}>
                        <small>City: </small>{" "}
                      </td>
                      <td sx={{ border: "1px solid grey" }}>{city}</td>
                    </tr>
                    <tr>
                      <td sx={{ border: "1px solid grey" }}>
                        <small>Notes: </small>
                      </td>
                      <td sx={{ border: "1px solid grey" }}>
                        {detail
                          ? detail.split(",").map((a) => <div>{a}</div>)
                          : "-NA-"}

                        <Text variant="description">
                          Detected Email/Mobile in Notes
                        </Text>
                        <br />
                        {matchPhone(detail).map((r1) => (
                          <SearchButton skey="mobile" value={r1} />
                        ))}
                        {matchEmail(detail).map((r1) => (
                          <SearchButton skey="email" value={r1} />
                        ))}
                      </td>
                    </tr>
                  </React.Fragment>
                )}
              </tbody>
            </table>
            {!!done && (
              <div>
                {done === "reject" ? (
                  <div
                    sx={{
                      py: 1,
                      px: 3,
                      fontWeight: 700,
                      color: "secondary",
                      backgroundColor: "highlight",
                    }}
                  >
                    Rejected
                  </div>
                ) : (
                  <div
                    sx={{
                      py: 1,
                      px: 3,
                      color: "primary",
                      fontWeight: 700,
                      backgroundColor: "highlight",
                    }}
                  >
                    Approved
                    <AbhyasiEntity id={id} display={id} />
                  </div>
                )}
              </div>
            )}

            {!done && (
              <Pivot linkSize="large" sx={{ mt: 2, bg: "#e8e8f4", p: 2 }}>
                <PivotItem headerText="Approval" itemIcon="Accept">
                  <ApproveRejectBtn
                    onAction={setDone}
                    id={r.id}
                    action="approve"
                  />
                </PivotItem>
                <PivotItem headerText="Rejection" itemIcon="Cancel">
                  <ApproveRejectBtn
                    onAction={setDone}
                    id={r.id}
                    action="reject"
                  />
                </PivotItem>
              </Pivot>
            )}
          </div>
        </div>
      );
    },
  ],
];

const MeSearch = () => {
  const [id] = useQueryParam("id", StringParam);
  let filters = {};
  let skey = "";
  if (id && parseInt(id, 10) > 0) {
    filters = { model_key: "id", model_value: id };
    skey = id;
  }

  return (
    <DefaultLayout>
      <div sx={{}}>
        <div sx={{ textAlign: "right", m: 2 }}>
          <DefaultButton
            styles={buttonStyles.blueLight}
            href="search-practitioners/"
            text="🔍 Search Existing Records in AIMS"
            target="_blank"
          />
        </div>
        <div sx={{ textAlign: "left", m: 2 }}>
          <Text variant="header">Pending Approvals (Central Team) </Text>
          {!!skey && (
            <Badge>
              aims {filters.model_key}: {filters.model_value}
            </Badge>
          )}
        </div>

        <SrcmSearchPage
          reduxKey={`me-approvals${skey}`}
          apiParams={{
            api: `/api/v2/me/approvals/`,
            client: "preceptorCandidateAppClient",
            methodParams: { approval_type: "seeker_to_practioner", ...filters },
          }}
          defaultKey="status"
          defaultValue="pending"
          keyOptions={[{ name: "status", label: "Approval Status" }]}
          colDefns={colDefns}
          noSearchDisplay
        />
      </div>
    </DefaultLayout>
  );
};

export default MeSearch;
